import { ref } from "vue";
import { defineStore } from "pinia";
import { HoursToSeconds } from "@ilihub/time";
import type { TrackingAbTests, BiTrackingHeader, TrackingMetadata } from "~/types/biTracking";
import { uuidv4 } from "~/utils/uuid";
import { useBMI } from "~/composables";
import appConfig from "~/app.config";
import type { QueryParameters } from "~/composables/useQueryParameters";

export const useBiTrackingStore = defineStore(
  "biTracking",
  () => {
    const { $pinia, $i18n } = useNuxtApp();

    const { locale } = $i18n;
    const { registrationParams } = storeToRefs(useOnboardingStore($pinia));
    const { skippedOnboarding } = storeToRefs(useOnboardingStore($pinia));
    const { getAge } = useAge();
    const { getBMI } = useBMI();

    const heyflowId = ref<string>();
    const sessionId = ref<string>();
    const metadata = ref<TrackingMetadata>({});
    const externalAbTests = ref<TrackingAbTests>({});
    const internalAbTests = ref<TrackingAbTests>({});
    const header = ref<BiTrackingHeader>({
      age: null,
      platform: "web",
      language: locale.value,
      country: null,
      sex: null,
      overallGoal: null,
      weightGoal: null,
      weightCurrent: null,
      bodyMassIndex: null,
      userUUID: null,
      abTests: {},
      properties: "{}",
    });

    function getRegistrationParamsHeaderValues() {
      return {
        userUUID: registrationParams.value.userUuid ?? null,
        overallGoal: registrationParams.value.goal ?? null,
        age: registrationParams.value.dateOfBirth ? getAge(registrationParams.value.dateOfBirth) : null,
        country: registrationParams.value.country ?? null,
        sex: registrationParams.value.sex ?? null,
        weightGoal: registrationParams.value.weightGoal ?? null,
        weightCurrent: registrationParams.value.startWeight ?? null,
        bodyMassIndex:
          registrationParams.value.startWeight && registrationParams.value.bodyHeight
            ? getBMI(registrationParams.value.startWeight, registrationParams.value.bodyHeight)
            : null,
      };
    }

    function updateHeaders(params: Partial<BiTrackingHeader> = {}): void {
      header.value = {
        ...header.value,
        abTests: { ...externalAbTests.value, ...internalAbTests.value },
        ...getRegistrationParamsHeaderValues(),
        ...params,
        properties: JSON.stringify({ ...JSON.parse(params.properties || "{}"), stripe_elements: true }),
      };
    }

    function getSessionId(): string {
      if (!sessionId.value) {
        sessionId.value = uuidv4();
      }
      return sessionId.value;
    }

    function $reset() {
      heyflowId.value = undefined;
      sessionId.value = undefined;
      metadata.value = {};
      externalAbTests.value = {};
      internalAbTests.value = {};
    }

    function handleQueryParameters(queryParameters: QueryParameters) {
      if (queryParameters.sessionShouldReset()) {
        $reset();
      }

      sessionId.value = queryParameters.getAndRemove("session_uuid") ?? sessionId.value;
      heyflowId.value = queryParameters.getAndRemove("flowid") ?? heyflowId.value;

      metadata.value.partner = queryParameters.getAndRemoveAll(["code", "sid", "partner"]) ?? metadata.value.partner;
      metadata.value.utm_campaign = queryParameters.getAndRemove("utm_campaign") ?? metadata.value.utm_campaign;
      metadata.value.utm_content = queryParameters.getAndRemove("utm_content") ?? metadata.value.utm_content;
      metadata.value.utm_medium = queryParameters.getAndRemove("utm_medium") ?? metadata.value.utm_medium;
      metadata.value.utm_source = queryParameters.getAndRemove("utm_source") ?? metadata.value.utm_source;
      metadata.value.utm_term = queryParameters.getAndRemove("utm_term") ?? metadata.value.utm_term;
      metadata.value.skipped_onboarding = skippedOnboarding.value;

      if (queryParameters.has("testname1") && queryParameters.has("testvariant1")) {
        externalAbTests.value[queryParameters.getAndRemove("testname1")] = queryParameters.getAndRemove("testvariant1");
      }

      if (queryParameters.has("testname2") && queryParameters.has("testvariant2")) {
        externalAbTests.value[queryParameters.getAndRemove("testname2")] = queryParameters.getAndRemove("testvariant2");
      }
    }

    return {
      $reset,
      heyflowId,
      metadata,
      sessionId,
      externalAbTests,
      internalAbTests,
      header,
      updateHeaders,
      getSessionId,
      handleQueryParameters,
    };
  },
  {
    persist: {
      pick: ["heyflowId", "sessionId", "externalAbTests", "internalAbTests", "metadata"],
      storage: persistedState.cookiesWithOptions({
        maxAge: HoursToSeconds(appConfig.cookieMaxAgeH),
      }),
    },
  },
);
