import { GrowthBook, configureCache } from "@growthbook/growthbook";
import { defineNuxtPlugin, useOnboardingStore, useRuntimeConfig } from "#imports";
import type { I18n } from "~/types/i18n";
import type { FeatureFlags } from "~/types/growthBook";

export default defineNuxtPlugin(async (nuxt) => {
  // mock the growthbook plugin for e2e tests
  if (useRequestHeaders(["user-agent"])["user-agent"] === "playwright-test" || navigator?.webdriver) {
    return {
      provide: {
        growthbook: { isOn: () => false } as never as GrowthBook,
      },
    };
  }

  const {
    public: { growthBookApiHost, growthBookClientKey, growthBookEnableDevMode, growthBookEncryptionKey },
  } = useRuntimeConfig();

  const i18n = nuxt.$i18n as I18n;

  const { $pinia } = useNuxtApp();
  const { user } = storeToRefs(useUserStore($pinia));
  const { registrationParams } = storeToRefs(useOnboardingStore($pinia));
  const biTrackingStore = useBiTrackingStore($pinia);
  const { sessionId } = storeToRefs(biTrackingStore);

  const { trackGeneric } = useBiTrackingClient();

  configureCache({
    disableCache: true,
  });

  const growthbook = new GrowthBook<FeatureFlags>({
    apiHost: growthBookApiHost,
    clientKey: growthBookClientKey,
    enableDevMode: growthBookEnableDevMode,
    decryptionKey: growthBookEncryptionKey,
    trackingCallback: async (experiment, result) => {
      if (nuxt.ssrContext) {
        // don't run the experiement tracking on the server due to event duplication
        return;
      }

      await trackGeneric({
        name: "experiment.assigned",
        properties: { experiment_id: experiment.key, variation_id: result.key },
      });
    },
  });

  async function setAttributes() {
    const attributes = {
      sex: registrationParams.value.sex || undefined,
      goal: registrationParams.value.goal || undefined,
      pro: !!(user.value && user.value.premium_type),
      country: registrationParams.value.country || undefined,
      language: i18n.locale.value || undefined,
      session_id: sessionId.value || biTrackingStore.getSessionId() || undefined,
      user_uuid: registrationParams.value.userUuid || undefined,
    };
    await growthbook.setAttributes(attributes);
  }

  await setAttributes();

  // Wait for features to be available
  await growthbook.init({ timeout: 2000 });

  if (!nuxt.ssrContext) {
    watchEffect(async () => {
      await setAttributes();
    });
  }

  return {
    provide: {
      growthbook,
    },
  };
});
